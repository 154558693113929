@import '~assets/styles/variables';

@keyframes flashandhide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  word-wrap: break-word;
}

.link {
  a {
    text-decoration: underline;
    text-wrap: wrap;
  }
}

.message {
  animation: flashandhide 600ms 3s;
  animation-fill-mode: forwards;
  position: absolute;
  @extend %font__h4;
  .md-icon-text {
    padding: 2rem;
  }
}
